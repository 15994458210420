import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { 
  Users, 
  TrendingUp, 
  FileText, 
  Activity, 
  Filter, 
  Download, 
  ChevronDown, 
  BarChart as ChartIcon, 
  List as ListIcon 
} from 'lucide-react';
import MainLayout from "../layouts/MainLayout";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const RelatorioPage = () => {
  const [dadosStatus, setDadosStatus] = useState(null);
  const [dadosComplementares, setDadosComplementares] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filtroStatus, setFiltroStatus] = useState('todos');
  const [visualizacao, setVisualizacao] = useState('grafico');
  const [rendaMaisUtilizada, setRendaMaisUtilizada] = useState(null);

  useEffect(() => {
    const buscarDadosRelatorio = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        const [responseStatus, responseComplementar] = await Promise.all([
          fetch(`${apiUrl}/relatorio-status`),
          fetch(`${apiUrl}/relatorios-complementares`),
        ]);

        const dadosStatus = await responseStatus.json();
        const dadosComplementares = await responseComplementar.json();

        setDadosStatus(dadosStatus);
        setDadosComplementares(dadosComplementares);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar relatórios:', error);
        setLoading(false);
      }
    };

    buscarDadosRelatorio();
  }, []);

  useEffect(() => {
    const fetchRendaMaisUtilizada = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/renda-mais-utilizada`);
        const data = await response.json();
        setRendaMaisUtilizada(data.rendaMaisUtilizada);
      } catch (error) {
        console.error('Erro ao buscar renda mais utilizada:', error);
      }
    };
  
    fetchRendaMaisUtilizada();
  }, []);

  // Carregamento com animação mais sofisticada
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <div className="text-center">
          <div className="relative">
            <div className="animate-spin absolute inset-0 rounded-full border-4 border-transparent border-t-4 border-indigo-500"></div>
            <div className="animate-ping absolute inset-0 rounded-full border-4 border-opacity-50 border-indigo-500"></div>
            <div className="w-24 h-24 bg-gray-800 rounded-full flex items-center justify-center">
              <Users className="text-indigo-500" size={40} />
            </div>
          </div>
          <p className="mt-6 text-xl text-gray-300 font-semibold tracking-wide">
            Carregando relatórios...
          </p>
        </div>
      </div>
    );
  }

  // Formatação de valor monetário
  const formatarValor = (valor) => {
    if (isNaN(valor)) return 'N/A';
    return new Intl.NumberFormat('pt-BR', { 
      style: 'currency', 
      currency: 'BRL' 
    }).format(parseFloat(valor));
  };

  // Gerar PDF com design moderno
  const gerarPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4"
    });

    // Definir cores e estilos
    const primaryColor = "#3B82F6";
    const textColor = "#1F2937";
    const headerColor = "#111827";

    // Adicionar cabeçalho
    doc.setFillColor(headerColor);
    doc.rect(0, 0, doc.internal.pageSize.width, 20, 'F');
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(16);
    doc.text("Relatório Completo de Clientes", doc.internal.pageSize.width / 2, 15, { align: "center" });

    // Estatísticas resumidas
    doc.setFontSize(12);
    doc.setTextColor(textColor);
    doc.text(`Total de Clientes: ${calcularTotalClientes()}`, 15, 30);
    doc.text(`Data de Geração: ${new Date().toLocaleDateString()}`, 15, 40);

    // Preparar dados para tabela
    const tableColumn = ["Status", "Nome", "Email", "Renda"];
    const tableRows = [];

    dadosStatus?.clientesPorStatus?.forEach((grupo) => {
      grupo.clientes.forEach((cliente) => {
        tableRows.push([
          grupo.status,
          cliente.nome || 'N/A',
          cliente.email || 'N/A',
          formatarValor(cliente.renda)
        ]);
      });
    });

    // Adicionar tabela com estilo moderno
    doc.autoTable({
      startY: 50,
      head: [tableColumn],
      body: tableRows,
      theme: 'striped',
      headStyles: { 
        fillColor: primaryColor, 
        textColor: 255,
        fontSize: 10 
      },
      styles: { 
        fontSize: 9,
        cellPadding: 3 
      },
      alternateRowStyles: { 
        fillColor: "#F3F4F6" 
      }
    });

    // Salvar com nome personalizado
    doc.save(`relatorio-clientes-${new Date().toISOString().split('T')[0]}.pdf`);
  };

  // Calcular total de clientes
  const calcularTotalClientes = () => {
    return dadosStatus?.metadata?.totalClientes ?? 0;
  };

  // Componente de Resumo de Cards
  const RenderResumoCards = () => (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
      {[
        { 
          icon: Users, 
          iconColor: "text-indigo-400", 
          title: "Total de Clientes", 
          value: calcularTotalClientes() 
        },
        { 
          icon: TrendingUp, 
          iconColor: "text-green-400", 
          title: "Renda Mais Utilizada", 
          value: rendaMaisUtilizada ? formatarValor(rendaMaisUtilizada) : 'N/A' 
        },
        { 
          icon: Activity, 
          iconColor: "text-purple-400", 
          title: "Status Principais", 
          value: dadosStatus?.estatisticasStatus?.[0]?.status ?? 'N/A' 
        },
        { 
          icon: FileText, 
          iconColor: "text-orange-400", 
          title: "Relatório Gerado", 
          value: dadosStatus?.metadata?.dataGeracao
            ? new Date(dadosStatus.metadata.dataGeracao).toLocaleDateString()
            : 'N/A' 
        }
      ].map((card, index) => (
        <div 
          key={index} 
          className="bg-gray-800 border border-gray-700 rounded-xl p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
        >
          <div className="flex items-center justify-between mb-4">
            <card.icon className={`${card.iconColor} opacity-80`} size={40} />
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-400 mb-2">{card.title}</h3>
            <p className="text-2xl font-bold text-white">{card.value}</p>
          </div>
        </div>
      ))}
    </div>
  );

  // Gráfico de Status
  const RenderGraficoStatus = () => (
    <div className="bg-gray-800 border border-gray-700 rounded-xl p-6">
      <h2 className="text-xl font-semibold text-gray-300 mb-6">Distribuição de Status</h2>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={dadosStatus?.estatisticasStatus ?? []}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
          <XAxis dataKey="status" stroke="#9CA3AF" />
          <YAxis stroke="#9CA3AF" />
          <Tooltip 
            contentStyle={{ 
              backgroundColor: '#1F2937', 
              borderColor: '#374151', 
              color: '#F9FAFB' 
            }} 
          />
          <Legend />
          <Bar 
            dataKey="total" 
            fill="#3B82F6" 
            className="transition-all duration-300 hover:opacity-80" 
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  // Lista de Clientes
  const RenderListaClientes = () => {
    const clientesFiltrados = dadosStatus?.clientesPorStatus.filter(
      (grupo) => filtroStatus === 'todos' || grupo.status === filtroStatus
    ) ?? [];
  
    return (
      <div className="bg-gray-800 border border-gray-700 rounded-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-300">Lista de Clientes</h2>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <select
                value={filtroStatus}
                onChange={(e) => setFiltroStatus(e.target.value)}
                className="appearance-none w-full bg-gray-700 text-white py-3 px-4 pr-8 rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                <option value="todos">Todos os Status</option>
                {dadosStatus?.estatisticasStatus?.map((status) => (
                  <option key={status.status} value={status.status}>
                    {status.status}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                <ChevronDown size={20} />
              </div>
            </div>
          </div>
        </div>
  
        {clientesFiltrados.map((grupo) => (
          <div key={grupo.status} className="mb-6">
            <h3 className="text-lg font-semibold bg-gray-700 p-4 rounded-lg text-gray-300">
              {grupo.status} ({grupo.totalClientes} clientes)
            </h3>
            <div className="overflow-x-auto mt-4">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-700 text-gray-300">
                    <th className="p-4 text-left">Nome</th>
                    <th className="p-4 text-left">Email</th>
                    <th className="p-4 text-left">Renda</th>
                  </tr>
                </thead>
                <tbody>
                  {grupo.clientes.map((cliente) => (
                    <tr 
                      key={cliente.id} 
                      className="border-b border-gray-700 hover:bg-gray-600 transition-colors duration-200"
                    >
                      <td className="p-4">{cliente.nome}</td>
                      <td className="p-4">{cliente.email}</td>
                      <td className="p-4">{formatarValor(cliente.renda)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white p-8">
        <div className="container mx-auto">
          <h1 className="text-4xl font-extrabold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-600">
            Painel de Relatórios
          </h1>

          <RenderResumoCards />

          <div className="mt-8 bg-gray-800 border border-gray-700 rounded-xl p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-300">Visualização</h2>
              <div className="flex items-center space-x-4">
                <button
                  className={`flex items-center px-4 py-2 rounded-lg transition-all duration-300 ${
                    visualizacao === 'grafico' 
                      ? 'bg-indigo-600 text-white' 
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                  onClick={() => setVisualizacao('grafico')}
                >
                  <ChartIcon className="mr-2" size={20} />
                  Gráficos
                </button>
                <button
                  className={`flex items-center px-4 py-2 rounded-lg transition-all duration-300 ${
                    visualizacao === 'lista' 
                      ? 'bg-indigo-600 text-white' 
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                  onClick={() => setVisualizacao('lista')}
                >
                  <ListIcon className="mr-2" size={20} />
                  Lista
                </button>
                <button
                  className="flex items-center px-4 py-2 rounded-lg bg-green-600 text-white hover:bg-green-700 transition-colors duration-300"
                  onClick={gerarPDF}
                >
                  <Download className="mr-2" size={20} />
                  Baixar PDF
                </button>
              </div>
            </div>
            {visualizacao === 'grafico' ? (
              <RenderGraficoStatus />
            ) : (
              <RenderListaClientes />
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default RelatorioPage;