import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Heart, MapPin, ChevronRight } from "lucide-react";
import Navbar from "../components/landpage/Navbar";
import Footer from "../components/landpage/Footer";

const Busca = () => {
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [favorites, setFavorites] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const busca = params.get("busca");

    if (busca) {
      setSearchTerm(busca);
      fetchProperties(busca);
    }
  }, [location]);

  const fetchProperties = async (busca) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/imoveis/busca`,
        {
          params: { busca },
        }
      );
      setProperties(response.data);
    } catch (error) {
      console.error("Erro ao buscar imóveis:", error);
    }
  };

  const toggleFavorite = (propertyId) => {
    setFavorites(prev => 
      prev.includes(propertyId) 
        ? prev.filter(id => id !== propertyId) 
        : [...prev, propertyId]
    );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-50 pt-24 pb-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl pt-26">
              Resultados da Busca
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              {properties.length} imóveis encontrados para "{searchTerm}"
            </p>
          </motion.div>

          <AnimatePresence>
            <motion.div 
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            >
              {properties.length > 0 ? (
                properties.map((property) => (
                  <motion.div
                    key={property.id}
                    variants={itemVariants}
                    whileHover={{ 
                      scale: 1.03,
                      boxShadow: "0 10px 20px rgba(0,0,0,0.12)"
                    }}
                    className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 ease-in-out"
                  >
                    <div className="relative">
                      <img
                        src={
                          property.imagens && JSON.parse(property.imagens)[0]
                            ? `${process.env.REACT_APP_API_URL}/${JSON.parse(property.imagens)[0]}`
                            : "https://via.placeholder.com/500"
                        }
                        alt={property.nome_imovel}
                        className="w-full h-56 object-cover"
                      />
                      <button 
                        onClick={() => toggleFavorite(property.id)}
                        className={`absolute top-4 right-4 p-2 rounded-full ${
                          favorites.includes(property.id) 
                            ? 'bg-red-500 text-white' 
                            : 'bg-white/75 text-gray-600'
                        } shadow-md hover:scale-110 transition-all`}
                      >
                        <Heart 
                          size={24} 
                          fill={favorites.includes(property.id) ? 'currentColor' : 'none'}
                        />
                      </button>
                    </div>

                    <div className="p-6">
                      <div className="flex items-center justify-between mb-3">
                        <h3 className="text-xl font-bold text-gray-900 truncate pr-4">
                          {property.nome_imovel}
                        </h3>
                        <span className="text-lg font-semibold text-blue-600">
                          R$ {property.valor_venda.toLocaleString('pt-BR')}
                        </span>
                      </div>

                      <div className="flex items-center text-gray-600 mb-4">
                        <MapPin size={18} className="mr-2 text-blue-500" />
                        <span className="truncate">{property.localizacao}</span>
                      </div>

                      <p className="text-gray-500 mb-4 line-clamp-3">
                        {property.descricao_imovel.substring(0, 150)}...
                      </p>

                      <div className="flex items-center justify-between">
                        <div className="flex space-x-2 items-center">
                          <span className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm">
                            {property.quartos} Quartos
                          </span>
                          <span className="px-3 py-1 bg-green-50 text-green-600 rounded-full text-sm">
                            {property.banheiro} Banheiros
                          </span>
                        </div>

                        <motion.a
                          href={`/imoveis/${property.id}`}
                          whileHover={{ x: 5 }}
                          className="flex items-center text-blue-600 hover:text-blue-800 font-semibold"
                        >
                          Detalhes <ChevronRight size={20} className="ml-1" />
                        </motion.a>
                      </div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="col-span-full text-center py-12"
                >
                  <h3 className="text-2xl font-semibold text-gray-600 mb-4">
                    Nenhum resultado encontrado
                  </h3>
                  <p className="text-gray-500">
                    Não foram encontrados imóveis para a busca "{searchTerm}"
                  </p>
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Busca;