import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X, Layout, Settings } from "lucide-react";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

const MainLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      {/* Mobile Sidebar Toggle */}
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`
          fixed top-4 left-4 z-50 
          ${sidebarOpen ? "hidden" : "block lg:hidden"}
          bg-blue-600/20 backdrop-blur-sm 
          text-blue-300 p-2 rounded-full 
          hover:bg-blue-600/30 
          transition-all duration-300
          shadow-lg
        `}
        onClick={toggleSidebar}
      >
        <Menu size={24} />
      </motion.button>

      {/* Sidebar */}
      <Sidebar 
        open={sidebarOpen} 
        handleDrawerClose={toggleSidebar} 
      />

      {/* Main Content Area */}
      <motion.div 
        initial={{ x: sidebarOpen ? 256 : 0 }}
        animate={{ 
          x: sidebarOpen ? 256 : 0,
          transition: { 
            type: "tween", 
            duration: 0.3 
          }
        }}
        className={`
          flex-1 relative 
          transition-all duration-300
          ${sidebarOpen ? "lg:pl-64" : "pl-0"}
        `}
      >
        {/* Top Navigation/Header */}
        <motion.header 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="sticky top-0 z-40 bg-gray-900/60 backdrop-blur-md shadow-lg"
        >
          <div className="container mx-auto flex justify-between items-center py-4 px-4">
            <div className="flex items-center space-x-4">
              <Layout className="text-blue-400" size={24} />
              
            </div>
            <div className="flex items-center space-x-4">
              <motion.button 
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="text-gray-300 hover:text-white"
              >
                <Settings size={20} />
              </motion.button>
            </div>
          </div>
        </motion.header>

        {/* Main Content */}
        <motion.main 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className=" mx-auto px-16 py-8"
        >
          <AnimatePresence mode="wait">
          <div className="">
            <div
              className={`flex-grow ${sidebarOpen ? "ml-64" : "ml-0"} lg:ml-64`}
            >
              {/* Container para o conteúdo principal */}
              <div className="">{children}</div>
            </div>
          </div>
          </AnimatePresence>
        </motion.main>

        {/* Footer */}
        <Footer />
      </motion.div>

      {/* Mobile Sidebar Overlay */}
      <AnimatePresence>
        {sidebarOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={toggleSidebar}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 lg:hidden"
          >
            <motion.button
              initial={{ x: -100 }}
              animate={{ x: 0 }}
              exit={{ x: -100 }}
              className="absolute top-4 right-4 text-white bg-red-500 p-2 rounded-full"
              onClick={toggleSidebar}
            >
              <X size={24} />
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MainLayout;