import React from 'react';
import { Close, Edit, DeleteOutline, InfoOutlined } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';

const ModalNotas = ({ notas, isOpen, onClose }) => {
  const modalVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.9 
    },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        type: "spring", 
        stiffness: 300, 
        damping: 20 
      }
    },
    exit: { 
      opacity: 0, 
      scale: 0.9,
      transition: { 
        duration: 0.2 
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4 overflow-y-auto">
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="relative bg-gray-800 text-white rounded-xl shadow-2xl w-full max-w-md max-h-[90vh] overflow-y-auto"
          >
            <div className="sticky top-0 bg-gray-900 z-10 flex items-center justify-between p-4 border-b border-gray-700">
              <h2 className="text-2xl font-bold flex items-center">
                <InfoOutlined className="mr-2 text-blue-400" />
                Notas do Cliente
              </h2>
              <button 
                onClick={onClose}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <Close />
              </button>
            </div>

            <div className="p-4">
              {notas.length === 0 ? (
                <div className="text-center py-8 text-gray-500">
                  <InfoOutlined className="mx-auto mb-4 text-4xl" />
                  <p>Nenhuma nota encontrada.</p>
                </div>
              ) : (
                <div className="space-y-4">
                  {notas.map((nota) => (
                    <div 
                      key={nota.id} 
                      className="relative bg-gray-700 rounded-lg p-4 hover:bg-gray-600 transition-colors group"
                    >
                      <p className="text-base mb-2">
                        {nota.texto || "Sem conteúdo"}
                      </p>
                      <div className="flex justify-between text-sm text-gray-400">
                        <span>
                          Criado por: {nota.criado_por_id || "Desconhecido"}
                        </span>
                        <span>
                          {nota.data_criacao
                            ? new Date(nota.data_criacao).toLocaleString()
                            : "Data desconhecida"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ModalNotas;