import React, { useEffect, useState } from "react";
import { 
  PeopleAlt as PeopleAltIcon, 
  PersonAdd as PersonAddIcon, 
  Dashboard as DashboardIcon, 
  Group as GroupIcon,
  QueryStats as QueryStatsIcon,
  BarChart as BarChartIcon,
  ShowChart as LineChartIcon,
  PieChart as PieChartIcon
} from '@mui/icons-material';
import LineChart from "./Charts/LineChart";
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import Top5Corretores from "../Top5Corretores";

const DashboardAdministrador = () => {
  const [dashboardData, setDashboardData] = useState({
    totalCorretores: 0,
    totalClientes: 0,
    totalCorrespondentes: 0,
    totalClientesAguardandoAprovacao: 0,
    clientesAguardandoAprovacao: [],
    top5Corretores: [],
    monthlyData: { labels: [], datasets: [] },
    weeklyData: { labels: [], datasets: [] },
    pieData: { labels: [], datasets: [] }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeChart, setActiveChart] = useState('line');

  const fetchData = async (url) => {
    const token = localStorage.getItem("authToken");
    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw new Error("Erro ao buscar dados do dashboard");
    }
    return await response.json();
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [
          dashboardData, 
          monthlyData, 
          weeklyData, 
          clientesAguardando
        ] = await Promise.all([
          fetchData(`${process.env.REACT_APP_API_URL}/dashboard`),
          fetchData(`${process.env.REACT_APP_API_URL}/dashboard/monthly`),
          fetchData(`${process.env.REACT_APP_API_URL}/dashboard/weekly`),
          fetchData(`${process.env.REACT_APP_API_URL}/clientes?status=aguardando_aprovacao`)
        ]);

        setDashboardData({
          totalCorretores: dashboardData.totalCorretores,
          totalClientes: dashboardData.totalClientes,
          totalCorrespondentes: dashboardData.totalCorrespondentes,
          totalClientesAguardandoAprovacao: clientesAguardando.length,
          clientesAguardandoAprovacao: clientesAguardando,
          top5Corretores: dashboardData.top5Corretores || [],
          monthlyData: {
            labels: [
              "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", 
              "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
            ],
            datasets: [{
              label: "Clientes Mensais",
              data: monthlyData.monthlyData,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            }]
          },
          weeklyData: {
            labels: [
              "Domingo", "Segunda", "Terça", "Quarta", 
              "Quinta", "Sexta", "Sábado"
            ],
            datasets: [{
              label: "Clientes Semanais",
              data: weeklyData.weeklyData,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            }]
          },
          pieData: {
            labels: ["Corretores", "Clientes", "Correspondentes"],
            datasets: [{
              data: [
                dashboardData.totalCorretores, 
                dashboardData.totalClientes, 
                dashboardData.totalCorrespondentes
              ],
              backgroundColor: [
                "rgba(255, 99, 132, 0.6)",
                "rgba(54, 162, 235, 0.6)",
                "rgba(255, 206, 86, 0.6)"
              ]
            }]
          }
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const DashboardCard = ({ title, value, icon, bgColor }) => (
    <div className={`${bgColor} rounded-3xl shadow-2xl p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-3xl relative overflow-hidden`}>
      <div className="absolute inset-0 bg-white/10 opacity-0 hover:opacity-20 transition-opacity duration-300"></div>
      <div className="flex items-center justify-between relative z-10">
        <div>
          <h3 className="text-lg font-semibold text-white/80 mb-2">{title}</h3>
          <p className="text-3xl font-bold text-white">{value}</p>
        </div>
        {React.cloneElement(icon, { 
          className: "w-16 h-16 text-white/70 opacity-70"
        })}
      </div>
    </div>
  );

  const StatusButton = ({ status }) => (
    <button className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold py-2 px-4 rounded-full hover:from-purple-700 hover:to-indigo-700 transition-all duration-300 transform hover:scale-105 shadow-lg">
      {status}
    </button>
  );

  const ChartSelector = () => {
    const chartTypes = [
      { type: 'line', icon: <LineChartIcon />, label: 'Mensal' },
      { type: 'bar', icon: <BarChartIcon />, label: 'Semanal' },
     
    ];

    return (
      <div className="flex justify-center space-x-4 mb-6">
        {chartTypes.map((chart) => (
          <button
            key={chart.type}
            onClick={() => setActiveChart(chart.type)}
            className={`
              flex items-center gap-2 px-4 py-2 rounded-full transition-all duration-300 
              ${activeChart === chart.type 
                ? 'bg-indigo-600 text-white shadow-lg' 
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}
            `}
          >
            {chart.icon}
            {chart.label}
          </button>
        ))}
      </div>
    );
  };

  const renderChart = () => {
    switch(activeChart) {
      case 'line':
        return dashboardData.monthlyData.labels.length > 0 ? (
          <LineChart data={dashboardData.monthlyData} />
        ) : null;
      case 'bar':
        return dashboardData.weeklyData.labels.length > 0 ? (
          <BarChart data={dashboardData.weeklyData} />
        ) : null;
      
      default:
        return null;
    }
  };

  if (loading) return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex items-center justify-center">
      <div className="animate-pulse text-white text-2xl flex items-center gap-4">
        <QueryStatsIcon className="animate-bounce" />
        Carregando Dados...
      </div>
    </div>
  );

  if (error) return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex items-center justify-center text-red-500 text-2xl">
      {error}
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8 text-white">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center flex items-center justify-center gap-4">
          <DashboardIcon className="text-4xl" /> Dashboard Administrador
        </h1>

        {/* Cards Overview */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-10">
          <DashboardCard 
            title="Total Corretores" 
            value={dashboardData.totalCorretores} 
            icon={<GroupIcon />}
            bgColor="bg-gradient-to-r from-blue-600 to-blue-400"
          />
          <DashboardCard 
            title="Total Clientes" 
            value={dashboardData.totalClientes} 
            icon={<PeopleAltIcon />}
            bgColor="bg-gradient-to-r from-green-600 to-green-400"
          />
          <DashboardCard 
            title="Correspondentes" 
            value={dashboardData.totalCorrespondentes} 
            icon={<DashboardIcon />}
            bgColor="bg-gradient-to-r from-yellow-600 to-yellow-400"
          />
          <DashboardCard 
            title="Aguardando Aprovação" 
            value={dashboardData.totalClientesAguardandoAprovacao} 
            icon={<PersonAddIcon />}
            bgColor="bg-gradient-to-r from-red-600 to-red-400"
          />
        </div>

        {/* Charts */}
        <div className="bg-gray-800 rounded-3xl p-8 shadow-2xl mb-10">
          <h2 className="text-3xl font-bold mb-6 flex items-center gap-3">
            <span className="w-3 h-3 bg-indigo-500 rounded-full"></span>
            Análise de Dados
          </h2>
          <ChartSelector />
          <div className="">
            {renderChart()}
          </div>
        </div>

        {/* Top 5 Corretores */}
        <div className="bg-gray-800 rounded-3xl p-8 shadow-2xl mb-10">
          <h2 className="text-3xl font-bold mb-6 flex items-center gap-3">
            <span className="w-3 h-3 bg-purple-500 rounded-full"></span>
            Top 5 Corretores
          </h2>
          <Top5Corretores corretores={dashboardData.top5Corretores} />
        </div>

        {/* Clientes Aguardando Aprovação */}
        <div className="bg-gray-800 rounded-3xl p-8 shadow-2xl">
          <h2 className="text-3xl font-bold mb-6 flex items-center gap-3">
            <span className="w-3 h-3 bg-red-500 rounded-full"></span>
            Clientes Aguardando Aprovação
          </h2>
          {dashboardData.clientesAguardandoAprovacao.length > 0 ? (
            <div className="grid gap-4">
              {dashboardData.clientesAguardandoAprovacao.map((cliente) => (
                <div 
                  key={cliente.id} 
                  className="bg-gray-700 p-4 rounded-xl flex justify-between items-center hover:bg-gray-600 transition-colors"
                >
                  <span className="text-lg font-medium">{cliente.nome}</span>
                  <StatusButton status="Aguardando Aprovação" />
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-400">
              Nenhum cliente aguardando aprovação
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardAdministrador;