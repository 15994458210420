import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaBed, FaBath, FaMapMarkerAlt, FaHeart } from "react-icons/fa";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const ListaImoveisPublico = ({ filters }) => {
  const [imoveis, setImoveis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [favorites, setFavorites] = useState(new Set());
  const navigate = useNavigate();

  // Função para processar imagens
  const processarImagens = (imagensString) => {
    try {
      const imagens = JSON.parse(imagensString?.replace(/\\/g, '/') || '[]')
        .filter(img => img && img !== 'null')
        .map(img => `${process.env.REACT_APP_API_URL}/${img}`);
      return imagens[0] || '/placeholder.jpg';
    } catch (err) {
      console.error('Erro ao processar imagens', err);
      return '/placeholder.jpg';
    }
  };

  useEffect(() => {
    const fetchImoveis = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/imoveis`
        );
        setImoveis(response.data);
      } catch (err) {
        setError("Erro ao carregar imóveis");
      } finally {
        setLoading(false);
      }
    };

    fetchImoveis();
  }, []);

  const filteredImoveis = imoveis.filter((imovel) => {
    if (!filters) return true;

    const withinPriceRange =
      (!filters.valorMin || imovel.valor_venda >= filters.valorMin) &&
      (!filters.valorMax || imovel.valor_venda <= filters.valorMax);
    const matchesTipo = !filters.tipo || imovel.tipo === filters.tipo;
    const matchesQuartos =
      !filters.quartos || imovel.quartos === parseInt(filters.quartos, 10);
    const matchesLocalizacao =
      !filters.localizacao || imovel.localizacao === filters.localizacao;

    const selectedTags = Array.isArray(filters.tags)
      ? filters.tags
      : [filters.tags].filter(Boolean);
    const hasTags = imovel.tags ? imovel.tags.split(",") : [];
    const matchesTags =
      !filters.tags ||
      selectedTags.every((tag) => hasTags.includes(tag.trim()));

    return (
      withinPriceRange &&
      matchesTipo &&
      matchesQuartos &&
      matchesLocalizacao &&
      matchesTags
    );
  });

  const formatarPreco = (valor) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
  };

  const toggleFavorite = (imovelId, e) => {
    e.stopPropagation();
    setFavorites((prev) => {
      const newFavorites = new Set(prev);
      if (newFavorites.has(imovelId)) {
        newFavorites.delete(imovelId);
      } else {
        newFavorites.add(imovelId);
      }
      return newFavorites;
    });
  };

  const getBadgeColor = (tipo) => {
    const colors = {
      'novo': 'bg-green-100 text-green-800',
      'usados': 'bg-blue-100 text-blue-800',
      'agio': 'bg-yellow-100 text-yellow-800'
    };
    return colors[tipo] || 'bg-gray-100 text-gray-800';
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {Array(16)
          .fill("")
          .map((_, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0.6 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
              className="bg-gray-200 border border-gray-300 rounded-2xl shadow-lg animate-pulse h-80"
            />
          ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-64 bg-red-50 rounded-2xl">
        <p className="text-red-600 text-2xl font-semibold">{error}</p>
        <p className="text-gray-500 mt-2">Por favor, tente novamente mais tarde</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {filteredImoveis.length === 0 ? (
        <div className="col-span-full flex flex-col items-center justify-center h-64 bg-gray-50 rounded-2xl">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-16 w-16 text-gray-400 mb-4" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h3a1 1 0 011 1v5m-4 0h4" 
            />
          </svg>
          <p className="text-xl text-gray-600 font-medium">
            Nenhum imóvel disponível no momento
          </p>
        </div>
      ) : (
        filteredImoveis.map((imovel) => (
          <motion.div
            key={imovel.id}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="relative group"
          >
            <div
              className="bg-white border border-gray-200 rounded-2xl shadow-lg overflow-hidden 
              transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 
              cursor-pointer"
              onClick={() => navigate(`/imoveis/${imovel.id}`)}
            >
              <div className="relative">
                <img
                  className="w-full h-56 object-cover transition-transform duration-300 group-hover:scale-110"
                  src={processarImagens(imovel.imagens)}
                  alt={`Imagem de ${imovel.nome_imovel}`}
                  loading="lazy"
                />
                <button
                  onClick={(e) => toggleFavorite(imovel.id, e)}
                  className={`absolute top-4 right-4 p-2 rounded-full transition-colors duration-300 
                    ${
                      favorites.has(imovel.id)
                        ? "bg-red-500 text-white"
                        : "bg-white/70 text-gray-700 hover:bg-red-100"
                    }`}
                >
                  <FaHeart className="w-5 h-5" />
                </button>
              </div>
              <div className="p-5">
                <div className="flex justify-between items-start mb-2">
                  <h5 className="text-xl font-bold text-gray-900 line-clamp-2">
                    {imovel.nome_imovel}
                  </h5>
                </div>
                <div className="flex items-center text-gray-600 mb-3">
                  <FaMapMarkerAlt className="mr-2" />
                  <span className="line-clamp-1">{imovel.localizacao}</span>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4">
                    <div className="flex items-center">
                      <FaBed className="mr-1 text-gray-600" />
                      <span>{imovel.quartos} Quartos</span>
                    </div>
                    <div className="flex items-center">
                      <FaBath className="mr-1 text-gray-600" />
                      <span>{imovel.banheiro || imovel.banheiros} Banheiros</span>
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <span className="text-2xl font-bold text-blue-600">
                    {formatarPreco(imovel.valor_venda)}
                  </span>
                  <span className={`text-sm px-2 py-1 rounded-full ${getBadgeColor(imovel.tipo)}`}>
                    {imovel.tipo}
                  </span>
                </div>
              </div>
            </div>
          </motion.div>
        ))
      )}
    </div>
  );
};

export default ListaImoveisPublico;