import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion"; // Added for smooth animations
import AddLembreteModal from "../components/AddLembreteModal";
import MainLayout from "../layouts/MainLayout";
import { 
  FaEdit, 
  FaTrashAlt, 
  FaCheck, 
  FaPlus, 
  FaList, 
  FaClipboardCheck 
} from "react-icons/fa";

const Lembretes = () => {
  const [lembretes, setLembretes] = useState([]);
  const [concluidos, setConcluidos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLembrete, setCurrentLembrete] = useState(null);
  const [error, setError] = useState(null);
  const [showConcluidos, setShowConcluidos] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchLembretes = async () => {
      try {
        const response = await fetch(`${API_URL}/lembretes`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setLembretes(data.filter((lembrete) => !lembrete.concluido));
          setConcluidos(data.filter((lembrete) => lembrete.concluido));
        } else {
          console.error("Data is not an array:", data);
          setLembretes([]);
          setConcluidos([]);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setLembretes([]);
        setConcluidos([]);
      }
    };

    fetchLembretes();
  }, [API_URL]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleEdit = (lembrete) => {
    setCurrentLembrete(lembrete);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Você realmente deseja excluir este lembrete?")) {
      try {
        const response = await fetch(`${API_URL}/lembretes/${id}`, {
          method: "DELETE",
        });
        if (!response.ok) {
          throw new Error("Erro ao excluir lembrete");
        }
        setLembretes(lembretes.filter((lembrete) => lembrete.id !== id));
        setConcluidos(concluidos.filter((lembrete) => lembrete.id !== id));
      } catch (error) {
        console.error("Erro ao excluir lembrete:", error);
        setError("Erro ao excluir lembrete. Tente novamente.");
      }
    }
  };

  const handleConcluir = async (id) => {
    const lembreteConcluido = lembretes.find((l) => l.id === id);
    if (lembreteConcluido) {
      try {
        await fetch(`${API_URL}/lembretes/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: "concluido" }),
        });

        setConcluidos((prev) => [
          ...prev,
          { ...lembreteConcluido, concluido: true },
        ]);
        setLembretes(lembretes.filter((l) => l.id !== id));
      } catch (error) {
        console.error("Erro ao atualizar lembrete:", error);
        setError("Erro ao concluir lembrete. Tente novamente.");
      }
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-black py-12 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-4xl mx-auto bg-gray-800 rounded-2xl shadow-2xl border border-gray-700 overflow-hidden"
        >
          <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border-b border-gray-700">
            <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 text-center mb-4">
              Lembretes
            </h1>
            
            <div className="flex justify-center space-x-4 mb-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setCurrentLembrete(null);
                  setIsModalOpen(true);
                }}
                className="flex items-center space-x-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white px-6 py-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1"
              >
                <FaPlus />
                <span>Adicionar Lembrete</span>
              </motion.button>
            </div>

            {/* Tab Buttons */}
            <div className="flex justify-center space-x-4 mb-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowConcluidos(false)}
                className={`flex items-center space-x-2 px-6 py-2 rounded-full transition-all duration-300 ${
                  !showConcluidos
                    ? "bg-gradient-to-r from-blue-600 to-purple-600 text-white"
                    : "bg-gray-700 text-gray-400"
                }`}
              >
                <FaList />
                <span>Ativos</span>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowConcluidos(true)}
                className={`flex items-center space-x-2 px-6 py-2 rounded-full transition-all duration-300 ${
                  showConcluidos
                    ? "bg-gradient-to-r from-green-600 to-teal-600 text-white"
                    : "bg-gray-700 text-gray-400"
                }`}
              >
                <FaClipboardCheck />
                <span>Concluídos</span>
              </motion.button>
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-red-400 text-center p-4 bg-red-900/20"
            >
              {error}
            </motion.div>
          )}

          {/* Table */}
          <div className="overflow-x-auto">
            <AnimatePresence>
              <motion.table 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-sm text-left text-gray-400"
              >
                <thead className="text-xs uppercase bg-gray-700/50">
                  <tr>
                    <th scope="col" className="px-6 py-4">Título</th>
                    <th scope="col" className="px-6 py-4">Descrição</th>
                    <th scope="col" className="px-6 py-4">Data</th>
                    <th scope="col" className="px-6 py-4">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {(showConcluidos ? concluidos : lembretes).map((lembrete) => (
                    <motion.tr
                      key={lembrete.id}
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 50 }}
                      className="border-b border-gray-700 hover:bg-gray-700/30 transition-colors duration-300"
                    >
                      <td className="px-6 py-4 font-medium text-white">
                        {lembrete.titulo}
                      </td>
                      <td className="px-6 py-4 text-gray-300">
                        {lembrete.descricao}
                      </td>
                      <td className="px-6 py-4 text-gray-400">
                        {formatDate(lembrete.data)}
                      </td>
                      <td className="px-6 py-4">
                        {!showConcluidos && (
                          <div className="flex space-x-4">
                            <motion.button
                              whileHover={{ scale: 1.2, rotate: 5 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => handleEdit(lembrete)}
                              className="text-blue-400 hover:text-blue-300 transition"
                            >
                              <FaEdit size={20} />
                            </motion.button>
                            <motion.button
                              whileHover={{ scale: 1.2, rotate: -5 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => handleDelete(lembrete.id)}
                              className="text-red-400 hover:text-red-300 transition"
                            >
                              <FaTrashAlt size={20} />
                            </motion.button>
                            <motion.button
                              whileHover={{ scale: 1.2 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => handleConcluir(lembrete.id)}
                              className="text-green-400 hover:text-green-300 transition"
                            >
                              <FaCheck size={20} />
                            </motion.button>
                          </div>
                        )}
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </motion.table>
            </AnimatePresence>
          </div>
        </motion.div>

        <AddLembreteModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setCurrentLembrete(null);
          }}
          onAddLembrete={(lembrete) => {
            setLembretes((prev) => [...prev, lembrete]);
            setIsModalOpen(false);
          }}
          currentLembrete={currentLembrete}
        />
      </div>
    </MainLayout>
  );
};

export default Lembretes;