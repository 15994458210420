import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { 
  FaBed, 
  FaBath, 
  FaWhatsapp, 
  FaMapMarkerAlt, 
  FaRegHeart, 
  FaHeart, 
  FaExpand, 
  FaTag 
} from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

const PropertyDetailSkeleton = () => (
  <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 animate-pulse">
    <div className="container mx-auto py-12 px-4 grid md:grid-cols-2 gap-8">
      <div className="bg-gray-300 h-96 rounded-xl"></div>
      <div className="space-y-4">
        <div className="h-12 bg-gray-300 rounded w-3/4"></div>
        <div className="h-8 bg-gray-300 rounded w-1/2"></div>
        <div className="space-y-2">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="h-4 bg-gray-300 rounded"></div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const ImovelDetail = () => {
  const { id } = useParams();
  const [imovel, setImovel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [favorited, setFavorited] = useState(false);
  const swiperRef = useRef(null);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const [propertyResponse, similarPropertiesResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/imoveis/${id}`),
          fetch(`${process.env.REACT_APP_API_URL}/imoveis/${id}/semelhantes`)
        ]);

        if (!propertyResponse.ok || !similarPropertiesResponse.ok) {
          throw new Error("Erro ao carregar detalhes do imóvel");
        }

        const propertyData = await propertyResponse.json();
        const similarProperties = await similarPropertiesResponse.json();

        setImovel({
          ...propertyData.data,
          similarProperties
        });

        const images = propertyData.data.imagens 
          ? JSON.parse(propertyData.data.imagens.replace(/&quot;/g, '"'))
          : [];

        setImageList(
          images.filter(Boolean).map(
            (img) => `${process.env.REACT_APP_API_URL}/${img.replace(/\\/g, "/")}`
          )
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyDetails();
  }, [id]);

  const toggleFavorite = () => {
    setFavorited(!favorited);
    // TODO: Implement actual favorite/unfavorite logic
  };

  const renderPropertyFeatures = () => {
    const features = [
      { icon: <FaBed />, text: `${imovel.quartos} Quartos` },
      { icon: <FaBath />, text: `${imovel.banheiro} Banheiros` },
      { icon: <FaMapMarkerAlt />, text: imovel.localizacao },
      { icon: <FaTag />, text: imovel.tipo }
    ];

    return (
      <div className="grid grid-cols-2 gap-6 mt-6">
        {features.map((feature, index) => (
          <motion.div 
            key={index} 
            className="flex items-center space-x-3 bg-white/50 backdrop-blur-lg p-4 rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <div className="text-blue-600 text-3xl">{feature.icon}</div>
            <span className="text-gray-700 font-semibold text-lg">{feature.text}</span>
          </motion.div>
        ))}
      </div>
    );
  };

  if (loading) return <PropertyDetailSkeleton />;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!imovel) return null;

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100"
    >
      <div className="container mx-auto py-12 px-4">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Image Gallery */}
          <div>
            <Swiper
              modules={[Navigation, Pagination, Zoom]}
              spaceBetween={10}
              navigation
              pagination={{ clickable: true }}
              zoom
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              className="rounded-2xl shadow-lg"
            >
              {imageList.map((img, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-zoom-container">
                    <img 
                      src={img} 
                      alt={`Imagem ${index + 1}`} 
                      className="w-full h-[500px] object-cover rounded-lg"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Property Details */}
          <motion.div 
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="bg-white/80 backdrop-blur-md rounded-2xl p-8 shadow-lg"
          >
            <div className="flex justify-between items-start mb-4">
              <h1 className="text-4xl font-bold text-gray-800">{imovel.nome_imovel}</h1>
              <motion.button 
                onClick={toggleFavorite}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="text-gray-500"
              >
                {favorited ? (
                  <FaHeart className="text-red-600 text-3xl" />
                ) : (
                  <FaRegHeart className="text-gray-500 text-3xl" />
                )}
              </motion.button>
            </div>

            <h2 className="text-2xl text-blue-600 font-semibold mb-4">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(imovel.valor_venda)}
            </h2>

            {renderPropertyFeatures()}

            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-3">Descrição</h3>
              <p className="text-gray-600 leading-relaxed line-clamp-4">
                {imovel.descricao_imovel}
              </p>
            </div>

            <div className="mt-6 flex space-x-4">
              <motion.a
                href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}&text=${encodeURIComponent(`Olá! Interessado no imóvel "${imovel.nome_imovel}"`)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex-1 bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition flex items-center justify-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaWhatsapp className="mr-2" /> Fale Conosco
              </motion.a>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default ImovelDetail;
