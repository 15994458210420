import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { 
  HiMenu, 
  HiOutlineLogout, 
  HiOutlineCog, 
  HiOutlineViewGrid, 
  HiOutlinePlusCircle, 
  HiOutlineClipboardList,
  HiOutlineDocumentText,
  HiOutlineUserGroup
} from "react-icons/hi";
import { 
  FaUserPlus, 
  FaHome, 
  FaUserTie, 
  FaUserShield, 
  FaBuilding, 
  FaQrcode,
  FaListAlt
} from "react-icons/fa";
import { useAuth } from "../context/AuthContext";

const Sidebar = ({ open, handleDrawerClose }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [timeRemaining, setTimeRemaining] = useState("N/A");
  const [activeSection, setActiveSection] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const calculateTimeRemaining = () => {
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    if (!tokenExpiry) return "N/A";
    const now = new Date().getTime();
    const expiryTime = parseInt(tokenExpiry, 10);
    const timeDiff = expiryTime - now;

    if (timeDiff <= 0) return "Expirado";

    const minutes = Math.floor(timeDiff / 60000);
    const seconds = Math.floor((timeDiff % 60000) / 1000);

    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  // Definição detalhada de permissões por role
  const menuItems = {
    "Administrador": {
      addItems: [
        { to: "/clientes/adicionar", icon: <FaUserPlus />, label: "Adicionar Cliente", permission: true },
        { to: "/corretores/adicionar", icon: <FaUserTie />, label: "Adicionar Corretor", permission: true },
        { to: "/correspondentes/adicionar", icon: <FaUserShield />, label: "Adicionar Correspondente", permission: true },
        { to: "/imoveis/adicionar", icon: <FaBuilding />, label: "Adicionar Imóvel", permission: true },
        { to: "/alugueis/adicionar", icon: <FaHome />, label: "Adicionar Aluguel", permission: true },
      ],
      listItems: [
        { to: "/proprietarios/lista", icon: <FaListAlt />, label: "Lista de Proprietários", permission: true },
        { to: "/clientes/lista", icon: <HiOutlineUserGroup />, label: "Lista de Clientes", permission: true },
        { to: "/imoveis/lista", icon: <FaBuilding />, label: "Lista de Imóveis", permission: true },
        { to: "/corretores/lista", icon: <FaUserTie />, label: "Lista de Corretores", permission: true },
        { to: "/correspondentes/lista", icon: <FaUserShield />, label: "Lista de Correspondentes", permission: true },
        { to: "/alugueis", icon: <FaHome />, label: "Lista de Aluguéis", permission: true },
        { to: "/lembretes", icon: <HiOutlineDocumentText />, label: "Lembretes", permission: true },
      ],
      extraItems: [
        { to: "/dashboard", icon: <HiOutlineViewGrid />, label: "Painel", permission: true },
        { to: "/whatsapp-qr", icon: <FaQrcode />, label: "Escanear QR Code", permission: true },
        { to: "/clientes-aluguel", icon: <HiOutlineUserGroup />, label: "Clientes de Aluguel", permission: true },
        { to: "/relatorio", icon: <HiOutlineDocumentText />, label: "Relatório", permission: true },
        { to: "/acessos", icon: <HiOutlineViewGrid />, label: "Lista de Acessos", permission: true },
      ]
    },
    "corretor": {
      addItems: [
        { to: "/clientes/adicionar", icon: <FaUserPlus />, label: "Adicionar Cliente", permission: true },
        { to: "/alugueis/adicionar", icon: <FaHome />, label: "Adicionar Aluguel", permission: true },
      ],
      listItems: [
        { to: "/clientes/lista", icon: <HiOutlineUserGroup />, label: "Lista de Clientes", permission: true },
        { to: "/imoveis/lista", icon: <FaBuilding />, label: "Lista de Imóveis", permission: true },
        { to: "/alugueis", icon: <FaHome />, label: "Lista de Aluguéis", permission: true },
      ],
      extraItems: [
        { to: "/dashboard", icon: <HiOutlineViewGrid />, label: "Painel", permission: true },
      ]
    },
    "Correspondente": {
      addItems: [
        { to: "/alugueis/adicionar", icon: <FaHome />, label: "Adicionar Aluguel", permission: true },
      ],
      listItems: [
        { to: "/proprietarios/lista", icon: <FaListAlt />, label: "Lista de Proprietários", permission: true },
        { to: "/clientes/lista", icon: <HiOutlineUserGroup />, label: "Lista de Clientes", permission: true },
        { to: "/imoveis/lista", icon: <FaBuilding />, label: "Lista de Imóveis", permission: true },
        { to: "/alugueis", icon: <FaHome />, label: "Lista de Aluguéis", permission: true },
      ],
      extraItems: [
        { to: "/dashboard", icon: <HiOutlineViewGrid />, label: "Painel", permission: true },
      ]
    }
  };

  const currentMenuItems = menuItems[user?.role] || { addItems: [], listItems: [], extraItems: [] };

  return (
    <div className={`
      fixed inset-y-0 left-0 
      w-72 
      bg-gray-900 
      text-white
      shadow-2xl 
      transition-transform 
      duration-300 
      ${open ? 'translate-x-0' : '-translate-x-full'}
      lg:translate-x-0
      z-50
      overflow-y-auto
    `}>
      {/* Cabeçalho do Perfil do Usuário */}
      <div className="bg-gradient-to-r from-gray-800 to-gray-700 text-white p-6">
        <div className="flex items-center space-x-4">
          <img
            src={
              user?.photo
                ? `${apiBaseUrl}/uploads/imagem_${user?.role}/${user.photo}`
                : "https://via.placeholder.com/150"
            }
            alt="Usuário"
            className="w-16 h-16 rounded-full border-4 border-gray-600 object-cover shadow-lg"
          />
          <div>
            <h2 className="text-lg font-semibold">{user?.first_name || "Nome do Usuário"}</h2>
            <p className="text-sm text-gray-300">{user?.role || "Função"}</p>
            <div className="mt-2 text-xs bg-red-700 rounded-full px-3 py-1 inline-block">
              Token: {timeRemaining}
            </div>
          </div>
        </div>
      </div>

      {/* Navegação */}
      <nav className="p-4">
        {/* Itens Extras */}
        {currentMenuItems.extraItems.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xs font-semibold text-gray-500 uppercase mb-2">Acesso Rápido</h3>
            {currentMenuItems.extraItems
              .filter(item => item.permission)
              .map((item, index) => (
                <Link
                  key={index}
                  to={item.to}
                  className="
                    flex 
                    items-center 
                    py-2 
                    px-4 
                    rounded-lg 
                    hover:bg-gray-800 
                    transition 
                    text-gray-300 
                    hover:text-white
                  "
                >
                  {item.icon}
                  <span className="text-sm ml-3">{item.label}</span>
                </Link>
              ))}
          </div>
        )}

        {/* Seção Adicionar */}
        {currentMenuItems.addItems.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xs font-semibold text-gray-500 uppercase mb-2">Adicionar Novo</h3>
            {currentMenuItems.addItems
              .filter(item => item.permission)
              .map((item, index) => (
                <Link
                  key={index}
                  to={item.to}
                  className="
                    flex 
                    items-center 
                    py-2 
                    px-4 
                    rounded-lg 
                    hover:bg-green-900 
                    transition 
                    text-gray-300 
                    hover:text-green-300
                  "
                >
                  {item.icon}
                  <span className="text-sm ml-3">{item.label}</span>
                </Link>
              ))}
          </div>
        )}

        {/* Seção Lista */}
        {currentMenuItems.listItems.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xs font-semibold text-gray-500 uppercase mb-2">Listas</h3>
            {currentMenuItems.listItems
              .filter(item => item.permission)
              .map((item, index) => (
                <Link
                  key={index}
                  to={item.to}
                  className="
                    flex 
                    items-center 
                    py-2 
                    px-4 
                    rounded-lg 
                    hover:bg-purple-900 
                    transition 
                    text-gray-300 
                    hover:text-purple-300
                  "
                >
                  {item.icon}
                  <span className="text-sm ml-3">{item.label}</span>
                </Link>
              ))}
          </div>
        )}

        {/* Ações de Rodapé */}
        <div className="border-t border-gray-800 pt-4 mt-4">
          <Link
            to="/configuracoes"
            className="
              flex 
              items-center 
              py-2 
              px-4 
              rounded-lg 
              hover:bg-gray-800 
              transition 
              text-gray-300 
              hover:text-white
            "
          >
            <HiOutlineCog className="mr-3" />
            <span className="text-sm">Configurações</span>
          </Link>
          <button
            onClick={handleLogout}
            className="
              w-full 
              flex 
              items-center 
              py-2 
              px-4 
              rounded-lg 
              hover:bg-red-900 
              transition 
              text-gray-300 
              hover:text-red-300
            "
          >
            <HiOutlineLogout className="mr-3" />
            <span className="text-sm">Sair</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;