import React, { useState, useEffect } from "react";
import { FaSearch, FaWhatsapp, FaSignInAlt, FaBars, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
    setShowSubmenu(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const submenuLinks = [
    { href: "/", label: "Início" },
    { href: "/imoveis?categoria=novo", label: "Imóveis Novos" },
    { href: "/imoveis?categoria=usados", label: "Imóveis Usados" },
    {
      href: "/imoveis?localizacao=Valparaiso de Goiás - Goiás",
      label: "Valparaíso de Goiás",
    },
    {
      href: "/imoveis?localizacao=cidade-ocidental",
      label: "Cidade Ocidental",
    },
    { href: "/imoveis?localizacao=luziania", label: "Luziania" },
    { href: "/imoveis?localizacao=Jardim Inga - Goias", label: "Jardim Ingá" },
    { href: "/imoveis?localizacao=brasilia", label: "Brasília" },
    { href: "/imoveis", label: "Todos os Imóveis" },
  ];

  return (
    <>
      {/* Top Announcement Bar */}
      <div 
        className={`bg-[#78b439] text-white text-center py-2 fixed w-full z-30 top-0 transition-all duration-300 ${
          isScrolled ? 'opacity-0 invisible' : 'opacity-100 visible'
        }`}
      >
        <p className="text-sm font-semibold">
          Bem-vindo ao nosso site! Aproveite nossas ofertas especiais.
        </p>
      </div>

      {/* Navbar Desktop */}
      <nav 
        className={`hidden md:block bg-white fixed w-full z-20 top-0 transition-all duration-300 ${
          isScrolled ? 'shadow-md border-b border-gray-200 py-3' : 'top-8 border-t border-gray-300 py-4'
        }`}
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img 
              src="/logo.png" 
              className="h-10 filter grayscale hover:filter-none transition-all duration-300" 
              alt="Logo" 
            />
          </a>
          <div className="flex-grow mx-8">
            <form
              id="frmBusca"
              role="search"
              method="GET"
              action="/busca/"
              className="w-full"
            >
              <div className="relative">
                <input
                  type="text"
                  id="buscaTopo"
                  name="busca"
                  placeholder="Encontre seu imóvel..."
                  className="w-full py-2 px-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#78b439] transition-all duration-300"
                />
                <button
                  type="submit"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#78b439] hover:text-green-700 transition-colors duration-300"
                >
                  <FaSearch />
                </button>
              </div>
            </form>
          </div>
          <div className="flex items-center space-x-4">
            <a
              href={`https://wa.me/${whatsappNumber}`}
              className="bg-[#25D366] text-white px-4 py-2 rounded-full font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 flex items-center space-x-2 transition-all duration-300 group"
            >
              <FaWhatsapp className="group-hover:scale-110 transition-transform" />
              <span>WhatsApp</span>
            </a>
            <a
              href="/login"
              className="bg-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center space-x-2 transition-all duration-300 group"
            >
              <FaSignInAlt className="group-hover:rotate-12 transition-transform" />
              <span>Corretores</span>
            </a>
          </div>
        </div>

        <AnimatePresence>
          {showSubmenu && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="border-t border-gray-300 bg-white"
            >
              <div className="flex justify-center items-center w-full py-2">
                <ul className="flex space-x-4 m-0 p-0">
                  {submenuLinks.map(({ href, label }) => (
                    <li key={href}>
                      <a
                        href={href}
                        className="block px-4 py-2 text-gray-700 hover:text-[#78b439] hover:bg-gray-100 rounded-md transition-all duration-300"
                      >
                        {label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>

      {/* Navbar Mobile */}
      <nav className="md:hidden bg-white fixed w-full z-20 top-8 border-t border-gray-300 shadow-md">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img 
              src="/logo.png" 
              className="h-10 filter grayscale hover:filter-none transition-all duration-300" 
              alt="Logo" 
            />
          </a>
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-gray-600 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-all duration-300"
            aria-controls="navbar-sticky"
            aria-expanded={isOpen}
          >
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-white flex flex-col items-center p-4 border-t border-gray-300"
            >
              <form
                id="frmBuscaMobile"
                role="search"
                method="GET"
                action="/busca/"
                className="w-full p-4"
              >
                <div className="relative">
                  <input
                    type="text"
                    id="buscaTopoMobile"
                    name="busca"
                    placeholder="Encontre seu imóvel..."
                    className="w-full py-2 px-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#78b439]"
                  />
                  <button
                    type="submit"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#78b439] hover:text-green-700"
                  >
                    <FaSearch />
                  </button>
                </div>
              </form>
              <div className="flex flex-col space-y-4 w-full px-4">
                <a
                  href={`https://wa.me/${whatsappNumber}`}
                  className="bg-[#25D366] text-white px-4 py-2 rounded-full font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 flex items-center justify-center space-x-2"
                >
                  <FaWhatsapp />
                  <span>WhatsApp</span>
                </a>
                <a
                  href="/login"
                  className="bg-blue-500 text-white px-4 py-2 rounded-full font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 flex items-center justify-center space-x-2"
                >
                  <FaSignInAlt />
                  <span>Corretores</span>
                </a>
              </div>
              {/* Submenu links for mobile */}
              <div className="mt-4 w-full">
                {submenuLinks.map(({ href, label }) => (
                  <a
                    key={href}
                    href={href}
                    className="block px-4 py-3 text-gray-700 hover:bg-gray-100 hover:text-[#78b439] transition-all duration-300"
                  >
                    {label}
                  </a>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </>
  );
};

export default Navbar;