import React, { useState, useEffect } from "react";
import axios from "axios";
import ModalCliente from "./ModalCliente";
import ModalNotas from "./ModalNotas";
import ModalEditarCliente from "./ModalEditarCliente";
import { FaEdit, FaTrashAlt, FaFilter, FaSearch } from "react-icons/fa";
import { MdVisibility, MdClear } from "react-icons/md";
import { useAuth } from "../context/AuthContext";

const statusMap = {
  aguardando_aprovacao: {
    name: "Aguardando Aprovação",
    color: "bg-amber-500",
  },
  reprovado: { name: "Cliente Reprovado", color: "bg-rose-500" },
  cliente_aprovado: { name: "Cliente Aprovado", color: "bg-emerald-500" },
  documentacao_pendente: {
    name: "Documentação Pendente",
    color: "bg-orange-500",
  },
  aguardando_cancelamento_qv: {
    name: "Aguardando Cancelamento / QV",
    color: "bg-violet-500",
  },
  proposta_apresentada: { name: "Proposta Apresentada", color: "bg-sky-500" },
  visita_efetuada: { name: "Visita Efetuada", color: "bg-teal-500" },
  fechamento_proposta: { name: "Fechamento Proposta", color: "bg-pink-500" },
  processo_em_aberto: { name: "Processo Aberto", color: "bg-slate-500" },
  conformidade: { name: "Conformidade", color: "bg-indigo-500" },
  concluido: { name: "Venda Concluída", color: "bg-teal-700" },
  nao_deu_continuidade: { name: "Não Deu Continuidade", color: "bg-gray-700" },
};

const ListaClientes = () => {
  const { user, authToken } = useAuth();
  const [clientes, setClientes] = useState([]);
  const [status, setStatus] = useState("Todos");
  const [corretor, setCorretor] = useState("Todos");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotasModalOpen, setIsNotasModalOpen] = useState(false);
  const [selectedNotas, setSelectedNotas] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const fetchClientes = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clientes`,
          {
            params: { status, corretor, dataInicio, dataFim },
          }
        );
        setClientes(response.data);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
        setError("Erro ao buscar clientes. Tente novamente mais tarde.");
      }
      setLoading(false);
    };

    fetchClientes();
  }, [status, corretor, dataInicio, dataFim]);

  // Sort clientes from newest to oldest based on created_at
  const sortedClientes = [...clientes].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  // Filtered clients based on search term
  const filteredClientes = sortedClientes.filter((cliente) =>
    cliente.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = async (id) => {
    if (window.confirm("Tem certeza que deseja excluir este cliente?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/clientes/${id}`);
        setClientes(clientes.filter((cliente) => cliente.id !== id));
      } catch (error) {
        console.error("Erro ao excluir cliente:", error);
      }
    }
  };
  const handleEditSave = async (clienteAtualizado) => {
    try {
      console.log("clienteAtualizado:", clienteAtualizado);
  
      // Garantir que o clienteAtualizado exista e tenha as propriedades necessárias
      if (!clienteAtualizado || typeof clienteAtualizado !== "object") {
        throw new Error("Cliente atualizado está indefinido ou inválido.");
      }
  
      // Verificar se o nome do cliente está presente
      if (!clienteAtualizado.nome) {
        throw new Error("Nome do cliente não encontrado.");
      }
  
      // Verificar se o status do cliente está presente
      if (!clienteAtualizado.status) {
        throw new Error("Status do cliente não encontrado.");
      }
  
      // Verificar se o campo documentos_pessoais está presente, senão inicializar
      clienteAtualizado.documentos_pessoais = clienteAtualizado.documentos_pessoais || [];
  
      // Atualizar o cliente no backend
      await axios.put(
        `${process.env.REACT_APP_API_URL}/clientes/${clienteAtualizado.id}`,
        clienteAtualizado
      );
  
      // Enviar notificação sobre a atualização do status, se presente
      if (clienteAtualizado.status) {
        await axios.post(`${process.env.REACT_APP_API_URL}/notificar`, {
          corretorId: clienteAtualizado.corretorId,
          status: clienteAtualizado.status,
          nomeCliente: clienteAtualizado.nome, // Garantir que o nome do cliente está sendo enviado
          nota: null, // Caso não haja nota, enviamos null
        });
      }
  
      // Atualizar a lista de clientes localmente
      setClientes(
        clientes.map((c) =>
          c.id === clienteAtualizado.id ? clienteAtualizado : c
        )
      );
  
      // Fechar o modal de edição
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Erro ao atualizar cliente:", error);
      setError("Erro ao atualizar cliente. Verifique os dados e tente novamente.");
    }
  };
  

  const handleViewDetails = (cliente) => {
    setSelectedCliente(cliente);
    setIsModalOpen(true);
  };

  const handleViewNotas = (notas) => {
    setSelectedNotas(notas || []);
    setIsNotasModalOpen(true);
  };

  const handleEdit = (cliente) => {
    setSelectedCliente(cliente);
    setIsEditModalOpen(true);
  };

  const handleStatusChange = () => {
    // Recarregar a lista de clientes após a atualização do status
    const fetchClientes = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clientes`,
          {
            params: { status, corretor, dataInicio, dataFim },
          }
        );
        setClientes(response.data);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
        setError("Erro ao buscar clientes. Tente novamente mais tarde.");
      }
      setLoading(false);
    };

    fetchClientes();
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 text-gray-100 p-6">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-400">
            Gestão de Clientes
          </h1>
          <button 
            onClick={() => setShowFilters(!showFilters)}
            className="bg-indigo-600 hover:bg-indigo-700 text-white p-3 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            <FaFilter />
          </button>
        </div>

        {/* Elegant Filters Section */}
        {showFilters && (
          <div className="bg-gray-800 border border-gray-700 rounded-lg p-6 mb-8 shadow-2xl">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="relative">
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Buscar por nome"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-white"
                />
              </div>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="w-full px-4 py-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-white"
              >
                <option value="Todos">Todos os Status</option>
                {Object.keys(statusMap).map((key) => (
                  <option key={key} value={key}>
                    {statusMap[key].name}
                  </option>
                ))}
              </select>
              <div className="flex space-x-2">
                <input
                  type="date"
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-white"
                />
                <input
                  type="date"
                  value={dataFim}
                  onChange={(e) => setDataFim(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-white"
                />
              </div>
            </div>
          </div>
        )}

        {/* Elegant Table */}
        <div className="bg-gray-800 border border-gray-700 rounded-lg shadow-2xl overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-700 border-b border-gray-600">
                <tr>
                  {["Nome", "Status", "Data Cadastro", "Corretor", "Notas", "Detalhes", "Ações"].map((header) => (
                    <th 
                      key={header} 
                      className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredClientes.map((cliente) => (
                  <tr 
                    key={cliente.id} 
                    className="hover:bg-gray-700 transition duration-200 border-b border-gray-700"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-white">
                        {cliente.nome}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span 
                        className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                          statusMap[cliente.status]?.color || 'bg-gray-500'
                        }`}
                      >
                        {statusMap[cliente.status]?.name || 'Status Indefinido'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {new Date(cliente.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {cliente.corretor 
                        ? `${cliente.corretor.first_name} ${cliente.corretor.last_name}`
                        : "Não Definido"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                    <button 
  onClick={() => handleViewNotas(cliente.notas || [])}
  className="relative text-indigo-400 hover:text-indigo-300"
>
  <MdVisibility className="w-6 h-6" />
  {(cliente.notas || []).length > 0 && (
    <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
      {(cliente.notas || []).length}
    </span>
  )}
</button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button 
                        onClick={() => handleViewDetails(cliente)}
                        className="text-sm text-cyan-400 hover:text-cyan-300 hover:underline"
                      >
                        Detalhes
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap flex space-x-3">
                      <button 
                        onClick={() => handleEdit(cliente)}
                        className="text-yellow-400 hover:text-yellow-300"
                      >
                        <FaEdit className="w-5 h-5" />
                      </button>
                      {user.role === "Administrador" && (
                        <button 
                          onClick={() => handleDelete(cliente.id)}
                          className="text-red-400 hover:text-red-300"
                        >
                          <FaTrashAlt className="w-5 h-5" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
                {filteredClientes.length === 0 && (
                  <tr>
                    <td colSpan="7" className="text-center py-8 text-gray-400">
                      Nenhum cliente encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Existing Modals */}
      <ModalCliente
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cliente={selectedCliente}
      />
      <ModalNotas
        isOpen={isNotasModalOpen}
        onClose={() => setIsNotasModalOpen(false)}
        notas={selectedNotas}
      />
      <ModalEditarCliente
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        cliente={selectedCliente || { documentos_pessoais: [] }}
        onSave={handleEditSave}
      />
    </div>
  );
};

export default ListaClientes;