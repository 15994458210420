import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { MapPin, Sparkles, Home, Landmark } from "lucide-react";

const PropertyCard = ({ property }) => {
  // Truncate description with ellipsis
  const truncateDescription = (text, maxLength = 100) => 
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  return (
    <div className="group relative overflow-hidden rounded-2xl bg-white shadow-lg transition-all duration-300 hover:shadow-2xl hover:scale-[1.02]">
      {/* Image with overlay */}
      <div className="relative h-56 overflow-hidden">
        <img
          src={
            property.imagens && JSON.parse(property.imagens)[0]
              ? `${process.env.REACT_APP_API_URL}/${JSON.parse(property.imagens)[0].replace(/\\/g, "/")}`
              : "https://via.placeholder.com/500"
          }
          alt={property.nome_imovel}
          className="absolute inset-0 h-full w-full object-cover transition-transform duration-300 group-hover:scale-110"
        />
        {/* Price Overlay */}
        <div className="absolute top-4 right-4 bg-blue-600/90 text-white px-3 py-2 rounded-full text-sm font-semibold">
          R$ {property.valor_venda.toLocaleString('pt-BR')}
        </div>
      </div>

      {/* Content */}
      <div className="p-6 space-y-4">
        <div>
          <h3 className="text-xl font-bold text-gray-800 mb-2 line-clamp-2">
            {property.nome_imovel}
          </h3>
          <div className="flex items-center text-gray-600 text-sm space-x-2">
            <MapPin className="w-4 h-4" />
            <span>{property.localizacao}</span>
          </div>
        </div>

        {/* Property Details */}
        <div className="flex justify-between text-gray-700">
          <div className="flex items-center space-x-2">
            <Home className="w-5 h-5 text-blue-600" />
            <span>{property.quartos} Quartos</span>
          </div>
          <div className="flex items-center space-x-2">
            <Landmark className="w-5 h-5 text-blue-600" />
            <span>{property.tipo === 'novo' ? 'Novo' : 'Usado'}</span>
          </div>
        </div>

        {/* Description */}
        <p className="text-gray-600 text-sm mb-4">
          {truncateDescription(property.descricao_imovel)}
        </p>

        {/* CTA Button */}
        <Link
          to={`/imoveis/${property.id}`}
          className="w-full flex items-center justify-center px-4 py-3 bg-blue-600 text-white rounded-lg font-semibold 
          hover:bg-blue-700 transition-colors duration-300 space-x-2 group"
        >
          <span>Detalhes do Imóvel</span>
          <Sparkles className="w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity" />
        </Link>
      </div>
    </div>
  );
};

const CardGrid = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/imoveis`
        );
        setProperties(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar imóveis:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 p-8">
        Erro ao carregar imóveis. Por favor, tente novamente mais tarde.
      </div>
    );
  }

  // Limit to display 4 properties
  const displayedProperties = properties.slice(0, 4);

  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-4xl font-extrabold text-center text-gray-900 mb-12 
        bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
        Imóveis em Destaque
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {displayedProperties.map((property) => (
          <PropertyCard key={property.id} property={property} />
        ))}
      </div>
    </div>
  );
};

export default CardGrid;