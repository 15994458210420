import React, { useState, useEffect } from "react";
import axios from "axios";
import { X, Save, UploadCloud, FileText } from "lucide-react";

const API_URL = process.env.REACT_APP_API_URL;
const statusMap = {
  aguardando_aprovacao: {
    name: "Aguardando Aprovação",
    color: "bg-amber-500",
  },
  reprovado: { name: "Cliente Reprovado", color: "bg-rose-500" },
  cliente_aprovado: { name: "Cliente Aprovado", color: "bg-emerald-500" },
  documentacao_pendente: {
    name: "Documentação Pendente",
    color: "bg-orange-500",
  },
  aguardando_cancelamento_qv: {
    name: "Aguardando Cancelamento / QV",
    color: "bg-violet-500",
  },
  proposta_apresentada: { name: "Proposta Apresentada", color: "bg-sky-500" },
  visita_efetuada: { name: "Visita Efetuada", color: "bg-teal-500" },
  fechamento_proposta: { name: "Fechamento Proposta", color: "bg-pink-500" },
  processo_em_aberto: { name: "Processo Aberto", color: "bg-slate-500" },
  conformidade: { name: "Conformidade", color: "bg-indigo-500" },
  concluido: { name: "Venda Concluída", color: "bg-teal-700" },
  nao_deu_continuidade: { name: "Não Deu Continuidade", color: "bg-gray-700" },
};

const statusList = Object.keys(statusMap).map(key => ({
  value: key,
  label: statusMap[key].name,
}));
const ElegantClientModal = ({ cliente, isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    cpf: "",
    valorRenda: "",
    estadoCivil: "",
    naturalidade: "",
    profissao: "",
    dataAdmissao: "",
    dataNascimento: "",
    rendaTipo: "",
    possuiCarteiraMaisTresAnos: false,
    numeroPis: "",
    possuiDependente: false,
    status: "",
    created_at: "",
    documentos_pessoais: [],
    extrato_bancario: [],
    documentos_dependente: [],
    documentos_conjuge: []
  });

  const [activeSection, setActiveSection] = useState("personal");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Sections for navigation
  const sections = [
    { id: "personal", label: "Informações Pessoais", icon: "👤" },
    { id: "professional", label: "Dados Profissionais", icon: "💼" },
    { id: "documents", label: "Documentos", icon: "📄" }
  ];

  // Initialize form data when cliente prop changes
  useEffect(() => {
    if (cliente && cliente.id) {
      setFormData({
        nome: cliente.nome || "",
        email: cliente.email || "",
        telefone: cliente.telefone || "",
        cpf: cliente.cpf || "",
        valorRenda: cliente.valor_renda || "",
        estadoCivil: cliente.estado_civil || "",
        naturalidade: cliente.naturalidade || "",
        profissao: cliente.profissao || "",
        dataAdmissao: cliente.data_admissao?.split("T")[0] || "",
        dataNascimento: cliente.data_nascimento?.split("T")[0] || "",
        rendaTipo: cliente.renda_tipo || "",
        possuiCarteiraMaisTresAnos: cliente.possui_carteira_mais_tres_anos || false,
        numeroPis: cliente.numero_pis || "",
        possuiDependente: cliente.possui_dependente || false,
        status: cliente.status || "",
        created_at: cliente.created_at || "",
        documentos_pessoais: cliente.documentos_pessoais || [],
        extrato_bancario: cliente.extrato_bancario || [],
        documentos_dependente: cliente.documentos_dependente || [],
        documentos_conjuge: cliente.documentos_conjuge || [],
        corretorId: cliente.corretorId || ""  // Adicionando corretorId aqui
      });
    }
  }, [cliente]);
  const { corretorId, created_at, documentos_pessoais, extrato_bancario, documentos_dependente, documentos_conjuge, ...rest } = formData;

  // Handle input changes
  const handleInputChange = (field) => (e) => {
    const value = e.target.type === 'checkbox' 
      ? e.target.checked 
      : e.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  // Handle file uploads
  const handleFileChange = (field) => (e) => {
    const files = Array.from(e.target.files);
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], ...files]
    }));
  };

  // Save client data
  const handleSave = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setMessage("Token de autenticação não encontrado.");
      return;
    }
  
    if (!cliente || !cliente.id) {
      setMessage("Cliente não está definido ou não possui ID.");
      return;
    }
  
    setLoading(true);
    const { 
      corretorId,  // Adiciona o corretorId explicitamente
      created_at, 
      documentos_pessoais, 
      extrato_bancario, 
      documentos_dependente, 
      documentos_conjuge, 
      ...rest 
    } = formData;
  
    // Garante que o corretorId seja mantido no objeto de envio
    const formDataToSubmit = new FormData();
Object.entries(rest).forEach(([key, value]) => {
  formDataToSubmit.append(key, value);
});

// Adiciona o corretorId ao FormData
if (corretorId) {
  formDataToSubmit.append("corretorId", corretorId);
}
  
    // Append files to FormData
    const fileFields = [
      { key: 'documentos_pessoais', files: documentos_pessoais },
      { key: 'extrato_bancario', files: extrato_bancario },
      { key: 'documentos_dependente', files: documentos_dependente },
      { key: 'documentos_conjuge', files: documentos_conjuge }
    ];
  
    fileFields.forEach(field => {
      if (field.files && Array.isArray(field.files)) {
        field.files.forEach(file => {
          formDataToSubmit.append(field.key, file);
        });
      } else {
        console.error(`field.files não é um array ou está indefinido para o campo ${field.key}`);
      }
    });
  
    try {
      const response = await axios.put(
        `${API_URL}/clientes/${cliente.id}`,
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Call onSave with updated client data
      onSave({
        ...rest,
        id: cliente.id,
        corretorId,  // Certifique-se de passar o corretorId de volta
        created_at: formData.created_at || ""
      });
  
      setMessage("Cliente atualizado com sucesso!");
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      setMessage("Erro ao atualizar cliente.");
      console.error(
        "Erro ao atualizar cliente:",
        error.response?.data || error
      );
    } finally {
      setLoading(false);
    }
  };
  

  // Render files list
  const renderFileList = (files, fieldName) => {
    // Check if files is undefined, and default to an empty array if so
    const safeFiles = Array.isArray(files) ? files : [];
    return (
      <div className="mt-2">
        {safeFiles.length > 0 ? (
          safeFiles.map((file, index) => (
            <div 
              key={index} 
              className="flex items-center justify-between bg-white/10 p-2 rounded-lg mb-2"
            >
              <div className="flex items-center space-x-2">
                <FileText className="w-5 h-5 text-blue-400" />
                <span className="text-sm text-white/70">{file.name}</span>
              </div>
              <button 
                onClick={() => {
                  setFormData(prev => ({
                    ...prev,
                    [fieldName]: prev[fieldName].filter((_, i) => i !== index)
                  }));
                }}
                className="text-red-500 hover:text-red-600"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ))
        ) : (
          <div>No files uploaded</div>
        )}
      </div>
    );
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/60 backdrop-blur-sm">
      <div className="relative w-11/12 max-w-4xl rounded-2xl bg-white/10 shadow-2xl ring-1 ring-white/10 backdrop-blur-xl">
  
        {/* Header with Sections */}
        <div className="flex border-b border-white/10">
          {sections.map((section) => (
            <button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`
                flex-1 p-4 flex items-center justify-center space-x-2 transition-all duration-300
                ${activeSection === section.id 
                  ? "bg-blue-500/20 text-blue-400" 
                  : "text-white/50 hover:bg-white/5"}
              `}
            >
              <span className="text-xl">{section.icon}</span>
              <span className="hidden md:inline">{section.label}</span>
            </button>
          ))}
        </div>
  
        {/* Error/Success Message */}
        {message && (
          <div className={`
            p-3 text-center 
            ${message.includes("sucesso") 
              ? "bg-green-500/20 text-green-400" 
              : "bg-red-500/20 text-red-400"}
          `}>
            {message}
          </div>
        )}
  
        {/* Content Sections */}
        <div className="p-6 space-y-6">
          {activeSection === "personal" && (
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-white/70 mb-2">Nome Completo</label>
                <input 
                  name="nome"
                  value={formData.nome}
                  onChange={handleInputChange("nome")}
                  placeholder="Digite o nome completo" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">E-mail</label>
                <input 
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange("email")}
                  placeholder="Digite o e-mail" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Telefone</label>
                <input 
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleInputChange("telefone")}
                  placeholder="(00) 00000-0000" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              <div>
  <label className="block text-sm text-white/70 mb-2">Status</label>
  <select
    name="status"
    value={formData.status}
    onChange={handleInputChange("status")}
    className="w-full bg-black/20 p-3 rounded-lg text-white border focus:ring-2 focus:ring-blue-500 transition"
  >
    <option value="" className="text-black">Selecione um status</option>
    {statusList.map((status) => (
      <option key={status.value} value={status.value} className="text-black">
        {status.label}
      </option>
    ))}
  </select>
</div>

              
              <div>
                <label className="block text-sm text-white/70 mb-2">CPF</label>
                <input 
                  name="cpf"
                  value={formData.cpf}
                  onChange={handleInputChange("cpf")}
                  placeholder="000.000.000-00" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Estado Civil</label>
                <input 
                  name="estadoCivil"
                  value={formData.estadoCivil}
                  onChange={handleInputChange("estadoCivil")}
                  placeholder="Digite o estado civil" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Naturalidade</label>
                <input 
                  name="naturalidade"
                  value={formData.naturalidade}
                  onChange={handleInputChange("naturalidade")}
                  placeholder="Digite a naturalidade" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Data de Nascimento</label>
                <input 
                  type="date" 
                  name="dataNascimento"
                  value={formData.dataNascimento}
                  onChange={handleInputChange("dataNascimento")}
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Possui Dependente</label>
                <input 
                  type="checkbox" 
                  name="possuiDependente"
                  checked={formData.possuiDependente}
                  onChange={handleInputChange("possuiDependente")}
                  className="w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500"
                />
              </div>
            </div>
          )}
          
          {activeSection === "professional" && (
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-white/70 mb-2">Profissão</label>
                <input 
                  name="profissao"
                  value={formData.profissao}
                  onChange={handleInputChange("profissao")}
                  placeholder="Digite a profissão" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Data de Admissão</label>
                <input 
                  type="date" 
                  name="dataAdmissao"
                  value={formData.dataAdmissao}
                  onChange={handleInputChange("dataAdmissao")}
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Tipo de Renda</label>
                <input 
                  name="rendaTipo"
                  value={formData.rendaTipo}
                  onChange={handleInputChange("rendaTipo")}
                  placeholder="Digite o tipo de renda" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Possui Carteira Há Mais de 3 Anos</label>
                <input 
                  type="checkbox" 
                  name="possuiCarteiraMaisTresAnos"
                  checked={formData.possuiCarteiraMaisTresAnos}
                  onChange={handleInputChange("possuiCarteiraMaisTresAnos")}
                  className="w-5 h-5 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500"
                />
              </div>
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Número do PIS</label>
                <input 
                  name="numeroPis"
                  value={formData.numeroPis}
                  onChange={handleInputChange("numeroPis")}
                  placeholder="Digite o número do PIS" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
             
              
              <div>
                <label className="block text-sm text-white/70 mb-2">Valor da Renda</label>
                <input 
                  name="valorRenda"
                  value={formData.valorRenda}
                  onChange={handleInputChange("valorRenda")}
                  placeholder="Digite o valor da renda" 
                  className="w-full bg-white/10 p-3 rounded-lg text-white border border-white/20 focus:ring-2 focus:ring-blue-500 transition"
                />
              </div>
            </div>
          )}
          
          {activeSection === "documents" && (
            <div className="space-y-4">
              {[
                { key: "documentos_pessoais", label: "Documentos Pessoais" },
                { key: "extrato_bancario", label: "Extrato Bancário" },
                { key: "documentos_dependente", label: "Documentos Dependente" },
                { key: "documentos_conjuge", label: "Documentos Cônjuge" }
              ].map(({ key, label }) => (
                <div key={key}>
                  <label className="block text-sm text-white/70 mb-2">{label}</label>
                  <div className="flex flex-col items-center justify-center w-full">
                    <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-white/20 border-dashed rounded-2xl cursor-pointer bg-white/5 hover:bg-white/10 transition">
                      <div className="flex flex-col items-center justify-center">
                        <UploadCloud className="w-10 h-10 text-white/50 mb-2" />
                        <p className="text-sm text-white/70">
                          Arraste e solte ou clique para selecionar
                        </p>
                      </div>
                      <input 
                        type="file" 
                        multiple
                        onChange={handleFileChange(key)}
                        className="hidden" 
                      />
                    </label>
                    {renderFileList(formData[key], key)}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
  
        {/* Footer with Save and Cancel buttons */}
        <div className="flex justify-end p-6 border-t border-white/10 bg-white/5">
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-600 text-white py-2 px-6 rounded-lg transition"
          >
            Cancelar
          </button>
          <button
            onClick={handleSave}
            className="ml-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg transition flex items-center space-x-2"
            disabled={loading}
          >
            {loading ? (
              <span className="animate-spin">🔄</span>
            ) : (
              <Save className="w-5 h-5" />
            )}
            <span>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ElegantClientModal;
