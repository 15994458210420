import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Database, 
  Server, 
  BarChart, 
  PieChart, 
  Zap,
  CheckCircle2,
  Sparkles
} from 'lucide-react';

const LoadingScreen = ({ onComplete }) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStage, setLoadingStage] = useState('Iniciando');
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const stages = [
      { text: 'Conectando ao Servidor', icon: Server },
      { text: 'Carregando Dados', icon: Database },
      { text: 'Processando Estatísticas', icon: BarChart },
      { text: 'Preparando Visualizações', icon: PieChart },
      { text: 'Finalizando', icon: Zap }
    ];

    const totalTime = 2000; // 4 segundos de carregamento total
    const stageTime = totalTime / stages.length;

    const progressInterval = setInterval(() => {
      setLoadingProgress(prev => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          setIsComplete(true);
          setTimeout(onComplete, 2000);
          return 100;
        }
        return prev + (100 / (totalTime / 100));
      });
    }, 100);

    const stageInterval = setInterval(() => {
      const currentStageIndex = Math.floor(loadingProgress / 20);
      if (currentStageIndex < stages.length) {
        setLoadingStage(stages[currentStageIndex].text);
      }
    }, stageTime);

    return () => {
      clearInterval(progressInterval);
      clearInterval(stageInterval);
    };
  }, [onComplete]);

  const backgroundVariants = {
    initial: { 
      background: 'linear-gradient(135deg, #111827, #1F2937)' 
    },
    animate: {
      background: [
        'linear-gradient(135deg, #111827, #1F2937)',
        'linear-gradient(135deg, #1F2937, #374151)',
        'linear-gradient(135deg, #111827, #1F2937)'
      ],
      transition: {
        duration: 6,
        repeat: Infinity,
        ease: 'easeInOut'
      }
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={backgroundVariants}
      className="min-h-screen flex items-center justify-center relative overflow-hidden p-6"
    >
      <div className="absolute inset-0 bg-black opacity-40 z-0"></div>
      
      <AnimatePresence>
        {!isComplete ? (
          <motion.div 
            key="loading"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.1 }}
            transition={{ duration: 0.5 }}
            className="relative z-10 w-full max-w-2xl bg-gray-800/80 backdrop-blur-lg rounded-2xl shadow-2xl border border-gray-700 p-8"
          >
            <div className="flex items-center justify-center mb-6">
              <motion.div 
                animate={{ 
                  rotate: [0, 360], 
                  scale: [1, 1.1, 1] 
                }}
                transition={{ 
                  duration: 2, 
                  repeat: Infinity, 
                  ease: "easeInOut" 
                }}
              >
                <Zap 
                  className="text-blue-500" 
                  size={64} 
                  strokeWidth={1.5} 
                />
              </motion.div>
            </div>

            <div className="w-full bg-gray-700 rounded-full h-3 mb-4 overflow-hidden">
              <motion.div
                className="bg-gradient-to-r from-blue-600 to-indigo-600 h-full"
                initial={{ width: '0%' }}
                animate={{ width: `${loadingProgress}%` }}
                transition={{ duration: 0.2 }}
              />
            </div>

            <div className="text-center">
              <h2 className="text-2xl font-bold text-white mb-4">
                {loadingStage}
              </h2>
              <p className="text-gray-400">
                Preparando seus dados com precisão...
              </p>
            </div>

            <motion.div 
              className="flex justify-center mt-6 space-x-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              {[Server, Database, BarChart, PieChart, Zap].map((Icon, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ 
                    opacity: index < Math.floor(loadingProgress / 20) ? 1 : 0.3, 
                    y: 0 
                  }}
                  transition={{ delay: index * 0.2 }}
                >
                  <Icon 
                    className={`
                      ${index < Math.floor(loadingProgress / 20) 
                        ? 'text-blue-500' 
                        : 'text-gray-600'
                      } 
                      transition-colors duration-300
                    `} 
                    size={32} 
                    strokeWidth={1.5} 
                  />
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        ) : (
          <motion.div
            key="success"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="relative z-10 w-full max-w-2xl bg-green-900/80 backdrop-blur-lg rounded-2xl shadow-2xl border border-green-800 p-8 text-center"
          >
            <div className="flex items-center justify-center mb-6">
              <motion.div
                initial={{ scale: 0.5, rotate: -180 }}
                animate={{ 
                  scale: [0.5, 1.2, 1], 
                  rotate: [0, 360],
                  transition: { 
                    duration: 1,
                    ease: "easeInOut"
                  }
                }}
              >
                <CheckCircle2 
                  className="text-green-400" 
                  size={84} 
                  strokeWidth={1.5} 
                />
              </motion.div>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <h2 className="text-3xl font-bold text-white mb-4 flex items-center justify-center gap-3">
                <Sparkles className="text-yellow-400" size={36} />
                Dados Carregados com Sucesso!
                <Sparkles className="text-yellow-400" size={36} />
              </h2>
              <p className="text-green-200 text-lg">
                Todas as etapas foram concluídas. Preparando para iniciar...
              </p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default LoadingScreen;