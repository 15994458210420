import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { motion } from 'framer-motion';
import { 
  TrendingUp as ArrowTrendingUpIcon, 
  Group as UserGroupIcon, 
  Description as ClipboardDocumentListIcon 
} from '@mui/icons-material';  // Importando ícones do Material-UI

// Register ChartJS components
ChartJS.register(
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend
);

const StatCard = ({ icon: Icon, title, value, color }) => (
  <motion.div 
    className={`bg-white/10 backdrop-blur-sm border border-white/10 rounded-xl p-6 flex items-center space-x-4 transform transition duration-300 hover:scale-105`}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    whileHover={{ scale: 1.05 }}
  >
    <Icon className={`w-12 h-12 ${color} opacity-70`} />
    <div>
      <p className="text-sm text-gray-300 uppercase tracking-wider">{title}</p>
      <p className="text-3xl font-bold text-white">{value}</p>
    </div>
  </motion.div>
);

const DashboardCorretor = () => {
  const [dashboardData, setDashboardData] = useState({
    totalClientes: 0,
    clientesNoMes: 0,
    monthlyData: { labels: [], data: [] },
    weeklyData: { labels: [], data: [] },
    clientesAguardandoAprovacao: []
  });

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const [dashboardResponse, monthlyResponse, weeklyResponse, clientesResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/dashboard`, { 
            headers: { Authorization: `Bearer ${token}` } 
          }),
          fetch(`${process.env.REACT_APP_API_URL}/dashboard/monthly`, { 
            headers: { Authorization: `Bearer ${token}` } 
          }),
          fetch(`${process.env.REACT_APP_API_URL}/dashboard/weekly`, { 
            headers: { Authorization: `Bearer ${token}` } 
          }),
          fetch(`${process.env.REACT_APP_API_URL}/clientes?status=aguardando_aprovacao`, { 
            headers: { Authorization: `Bearer ${token}` } 
          })
        ]);

        const dashboardData = await dashboardResponse.json();
        const monthlyData = await monthlyResponse.json();
        const weeklyData = await weeklyResponse.json();
        const clientesData = await clientesResponse.json();

        setDashboardData({
          totalClientes: dashboardData.totalClientes,
          clientesNoMes: dashboardData.clientesEsteMes,
          monthlyData: {
            labels: monthlyData.monthlyData.map((_, index) => `Mês ${index + 1}`),
            data: monthlyData.monthlyData
          },
          weeklyData: {
            labels: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            data: weeklyData.weeklyData
          },
          clientesAguardandoAprovacao: clientesData
        });
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchAllData();
  }, []);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'white'
        }
      },
      x: {
        ticks: {
          color: 'white'
        }
      }
    }
  };

  const monthlyChartData = {
    labels: dashboardData.monthlyData.labels,
    datasets: [{
      label: 'Clientes Mensais',
      data: dashboardData.monthlyData.data,
      borderColor: 'rgb(134, 239, 172)',
      backgroundColor: 'rgba(134, 239, 172, 0.2)',
      tension: 0.4
    }]
  };

  const weeklyChartData = {
    labels: dashboardData.weeklyData.labels,
    datasets: [{
      label: 'Clientes Semanais',
      data: dashboardData.weeklyData.data,
      backgroundColor: 'rgb(96, 165, 250)',
      borderColor: 'rgb(96, 165, 250)',
    }]
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-black p-8 text-white">
      <motion.h1 
        className="text-4xl font-extrabold mb-8 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
      >
        Dashboard Corretor
      </motion.h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <StatCard 
          icon={UserGroupIcon} 
          title="Clientes Totais" 
          value={dashboardData.totalClientes} 
          color="text-green-400"
        />
        <StatCard 
          icon={ArrowTrendingUpIcon} 
          title="Clientes no Mês" 
          value={dashboardData.clientesNoMes} 
          color="text-blue-400"
        />
        <StatCard 
          icon={ClipboardDocumentListIcon} 
          title="Aguardando Aprovação" 
          value={dashboardData.clientesAguardandoAprovacao.length} 
          color="text-yellow-400"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <motion.div 
          className="bg-white/10 backdrop-blur-sm border border-white/10 rounded-xl p-6"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <h2 className="text-2xl font-bold mb-4">Clientes Mensais</h2>
          <Line data={monthlyChartData} options={chartOptions} />
        </motion.div>

        <motion.div 
          className="bg-white/10 backdrop-blur-sm border border-white/10 rounded-xl p-6"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <h2 className="text-2xl font-bold mb-4">Clientes Semanais</h2>
          <Bar data={weeklyChartData} options={chartOptions} />
        </motion.div>
      </div>

      <motion.div 
        className="mt-8 bg-white/10 backdrop-blur-sm border border-white/10 rounded-xl p-6"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {dashboardData.clientesAguardandoAprovacao.length > 0 ? (
  <table className="w-full">
    <thead>
      <tr className="border-b border-white/20">
        <th className="py-3 text-left">Nome</th>
        <th className="py-3 text-right">Status</th>
      </tr>
    </thead>
    <tbody>
      {dashboardData.clientesAguardandoAprovacao
        .filter(cliente => cliente.status === 'aguardando_aprovacao') // Filtra clientes com o status "aguardando_aprovacao"
        .map((cliente) => (
          <tr key={cliente.id} className="border-b border-white/10 hover:bg-white/5 transition">
            <td className="py-3">{cliente.nome}</td>
            <td className="py-3 text-right">
              <span className="text-yellow-500 bg-yellow-500/20 px-3 py-1 rounded-full">
                Aguardando
              </span>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
) : (
  <p className="text-gray-400">Nenhum cliente aguardando aprovação</p>
)}

      </motion.div>
    </div>
  );
};

export default DashboardCorretor;
