import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import {
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Warning as WarningIcon
} from '@mui/icons-material';

const ModalCliente = ({ cliente, isOpen, onClose, onStatusChange }) => {
  const [nota, setNota] = useState("");
  const [notas, setNotas] = useState([]);
  const [status, setStatus] = useState(cliente?.status || "");
  const [activeSection, setActiveSection] = useState("details");
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [confirmConclusion, setConfirmConclusion] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchNotas = async () => {
      if (cliente?.id) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/clientes/${cliente.id}/notas`
          );
          setNotas(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
          console.error("Erro ao buscar notas:", error);
        }
      }
    };

    fetchNotas();
  }, [cliente]);

  if (!isOpen) return null;

  const handleAddNota = () => {
    if (nota.trim() === "") return;

    const novaNota = {
      cliente_id: cliente.id,
      processo_id: null,
      nova: true,
      destinatario: cliente.nome,
      texto: nota,
      data_criacao: new Date(),
      criado_por_id: user ? user.first_name : null,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/notas`, novaNota)
      .then((response) => {
        setNotas((prevNotas) => [...prevNotas, response.data]);
        setNota("");
      })
      .catch((error) => console.error("Erro ao adicionar nota:", error));
  };

  const handleConcluirNota = (notaId) => {
    setConfirmConclusion(notaId);
  };

  const confirmConcluirNota = () => {
    if (confirmConclusion) {
      // Lógica para concluir a nota no backend
      axios.patch(`${process.env.REACT_APP_API_URL}/notas/${confirmConclusion}/concluir`)
        .then(() => {
          setNotas(prevNotas => prevNotas.filter(n => n.id !== confirmConclusion));
          setConfirmConclusion(null);
        })
        .catch(error => console.error("Erro ao concluir nota:", error));
    }
  };

  const handleDeletarNota = (notaId) => {
    setConfirmDelete(notaId);
  };

  const confirmDeletarNota = () => {
    if (confirmDelete) {
      axios.delete(`${process.env.REACT_APP_API_URL}/notas/${confirmDelete}`)
        .then(() => {
          setNotas(prevNotas => prevNotas.filter(n => n.id !== confirmDelete));
          setConfirmDelete(null);
        })
        .catch(error => console.error("Erro ao deletar nota:", error));
    }
  };

  const renderConfirmationPopup = (title, message, onConfirm, onCancel) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100] p-4">
      <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full">
        <div className="flex items-center space-x-4 mb-4">
          <WarningIcon className="text-yellow-500 w-10 h-10" />
          <h3 className="text-xl font-bold text-white">{title}</h3>
        </div>
        <p className="text-gray-300 mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          <button 
            onClick={onCancel}
            className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Cancelar
          </button>
          <button 
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );

  const renderClientDetails = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 bg-gray-800 rounded-lg">
      {[
        { label: "Nome", value: cliente.nome },
        { label: "Email", value: cliente.email },
        { label: "Telefone", value: cliente.telefone },
        { label: "CPF", value: cliente.cpf },
        { label: "Renda", value: cliente.valor_renda },
        { label: "Estado Civil", value: cliente.estado_civil },
        { label: "Naturalidade", value: cliente.naturalidade },
        { label: "Profissão", value: cliente.profissao },
        { 
          label: "Data de Admissão", 
          value: new Date(cliente.data_admissao).toLocaleDateString() 
        },
        { 
          label: "Data de Nascimento", 
          value: new Date(cliente.data_nascimento).toLocaleDateString() 
        },
        { label: "Tipo de Renda", value: cliente.renda_tipo },
        { 
          label: "Carteira de Trabalho (Mais de 3 Anos)", 
          value: Boolean(cliente.possui_carteira_mais_tres_anos) ? "Sim" : "Não" 
        },
        { 
          label: "Possui Dependente", 
          value: Boolean(cliente.possui_dependente) ? "Sim" : "Não" 
        }
      ].map(({ label, value }) => (
        <div key={label} className="flex flex-col">
          <span className="text-sm text-gray-400 mb-1">{label}</span>
          <span className="text-white font-medium truncate">{value}</span>
        </div>
      ))}
    </div>
  );

  const renderDocuments = () => (
    <div className="space-y-4 p-4">
      {[
        { 
          label: "Documentos Pessoais", 
          path: cliente.documentos_pessoais,
          icon: <DescriptionIcon className="w-6 h-6 text-blue-500" />
        },
        { 
          label: "Extrato Bancário", 
          path: cliente.extrato_bancario,
          icon: <DescriptionIcon className="w-6 h-6 text-green-500" />
        },
        { 
          label: "Ficha de Visita", 
          path: cliente.ficha_de_visita,
          icon: <DescriptionIcon className="w-6 h-6 text-purple-500" />
        }
      ].map(({ label, path, icon }) => path && (
        <button
          key={label}
          onClick={() => window.open(`${process.env.REACT_APP_API_URL}/${path}`, "_blank")}
          className="w-full flex items-center justify-between bg-gray-700 hover:bg-gray-600 rounded-lg p-3 transition-colors duration-200"
        >
          <div className="flex items-center space-x-3">
            {icon}
            <span className="text-white font-medium">{label}</span>
          </div>
          <EditIcon className="w-5 h-5 text-gray-400" />
        </button>
      ))}
    </div>
  );

  const renderNotas = () => (
    <div className="space-y-4 p-4 max-h-96 overflow-y-auto">
      {notas.length === 0 ? (
        <div className="text-center text-gray-400 py-4">
          Nenhuma nota adicionada ainda
        </div>
      ) : (
        notas.map((nota) => (
          <div 
            key={nota.id} 
            className="bg-gray-700 rounded-lg p-4 shadow-md hover:shadow-lg transition-all duration-300"
          >
            <p className="text-white mb-2">{nota.texto}</p>
            <div className="flex justify-between items-center text-sm text-gray-400">
              <span>
                Criado por: {nota.criado_por_id || "Desconhecido"} 
                {" "}em {new Date(nota.data_criacao).toLocaleDateString()}
              </span>
              <div className="flex space-x-2">
                <button 
                  onClick={() => handleConcluirNota(nota.id)}
                  className="text-green-500 hover:text-green-400"
                >
                  <CheckCircleIcon className="w-5 h-5" />
                </button>
                <button 
                  onClick={() => handleDeletarNota(nota.id)}
                  className="text-red-500 hover:text-red-400"
                >
                  <DeleteIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50 p-4">
      <div className="bg-gray-900 text-white rounded-2xl shadow-2xl w-full max-w-5xl h-[90vh] flex flex-col overflow-hidden">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-800">
          <h2 className="text-3xl font-bold text-white">Detalhes do Cliente</h2>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-white transition-colors"
          >
            <CloseIcon className="w-8 h-8" />
          </button>
        </div>

        {/* Navigation */}
        <div className="flex border-b border-gray-800">
          {[
            { id: "details", label: "Informações", icon: DescriptionIcon },
            { id: "documents", label: "Documentos", icon: DescriptionIcon },
            { id: "notes", label: "Notas", icon: EditIcon }
          ].map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setActiveSection(id)}
              className={`flex-1 p-4 flex items-center justify-center space-x-2 
                ${activeSection === id 
                  ? 'bg-blue-600 text-white' 
                  : 'text-gray-400 hover:bg-gray-800'
                } transition-colors duration-200`}
            >
              <Icon className="w-6 h-6" />
              <span className="font-medium">{label}</span>
            </button>
          ))}
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          {activeSection === "details" && renderClientDetails()}
          {activeSection === "documents" && renderDocuments()}
          {activeSection === "notes" && renderNotas()}
        </div>

        {/* Add Note */}
        {activeSection === "notes" && (
          <div className="border-t border-gray-800 p-4 flex items-center space-x-4">
            <input 
              type="text" 
              value={nota}
              onChange={(e) => setNota(e.target.value)}
              className="flex-1 bg-gray-700 text-white rounded-lg p-2 outline-none"
              placeholder="Digite uma nova nota"
            />
            <button 
              onClick={handleAddNota}
              className="bg-blue-600 hover:bg-blue-500 text-white rounded-lg p-2 transition-colors duration-200"
            >
              Adicionar
            </button>
          </div>
        )}
        {/* Confirmation Popups */}
      {confirmDelete && renderConfirmationPopup(
        "Excluir Nota",
        "Tem certeza de que deseja excluir esta nota? Esta ação não pode ser desfeita.",
        confirmDeletarNota,
        () => setConfirmDelete(null)
      )}

      {confirmConclusion && renderConfirmationPopup(
        "Concluir Nota",
        "Tem certeza de que deseja concluir esta nota?",
        confirmConcluirNota,
        () => setConfirmConclusion(null)
      )}
   
  
      </div>
    </div>
    
  );
};

export default ModalCliente;
